<template>
<!-- 云健身-活跃星期分布 -->
    <div class="fitness darkBack">
        <div class="fitnessnav">
            <div>活跃星期分布</div>
        </div>
        <div id="week1" style="width:5.60rem; height:2.20rem;"></div>
    </div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";

export default {
    data () {
        return {
            sport:{
                data: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
                number:[120, 200, 150, 80, 70, 110, 130],
                color1:"#E68739",
                color2:"#EAAE3140",     // 网格线的颜色
                color3:"#E5813A",     // 渐变初始的颜色
                color4:"#EFDC29",     // 渐变末尾颜色
            },
        }
    },
    methods: {
        // 柱状图
        fitness(id, number){
            let that = this;
            // let canye = that.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            // var data = number.number
            // var data = [];
            // for (let i = 0; i < 17; ++i) {
            //     data.push(Math.round(Math.random() * 200));
            // }

            let option = {
                xAxis: {
                    type: 'category',
                    offset: 5,          // x轴 字与轴的距离
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: number.color1,
                        }
                    },
                    axisLabel:{
                      textStyle: {
                        show:true,
                        fontSize: '0.13rem'
                      }
                    },
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    data: number.data
                },
                grid:{
                    left:"8%",
                    right:"8%",
                    top:"15%",
                    bottom: "18%"
                },
                yAxis: {
                    type: 'value',
                    splitLine: {            // 网格线的颜色
                        show: true,
                        splitNumber: 5,
                        lineStyle:{
                            color: number.color2,
                            width: 1,
                            type: 'solid',
                        },
                　　},
                    axisLabel: {               // y轴字体的颜色
                      textStyle: {
                        show:true,
                        fontFamily:'微软雅黑',
                        color: "#7EA7E299",
                        fontSize: '0.13rem',
                      },
                    },
                },
                series: [{
                    data: number.number,
                    type: 'bar',
                    barWidth : '40%',              // 柱子的宽度
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 1 , 0, 0, [{
                                offset: 0,
                                color: number.color3 // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: number.color4 // 100% 处的颜色
                            }], false)
                        }
                    },
                }]
            };;
                canye.setOption(option);
            // }
            //图表自动缩放
            window.addEventListener("resize",function(){
            	canye.resize();
            });
        },
        // 获取数据
        getData(){
            let that = this;
            // this.fitness("week1", this.sport)

            var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
            let url;
            if(info.is_city == 1) url = window.appHost+"/api/v1/screen/Cloud_Gym/active_week"
            else url = window.appHost+"/api/v1/screen/Cloud_Gym/active_week?area="+info.name

            let result = window.ajaxRequest(url,JSON.stringify(data))
            // console.log(result)
            that.sport.data = [];
            that.sport.number = [];
            result.data.forEach((item,index)=>{
                that.sport.data.push(item.num);
                that.sport.number.push(item.week);
            })
            that.fitness("week1", that.sport)
        }
    },
    mounted () {
        this.getData()
    }
}
</script>

<style scoped>
.fitness{
    width:5.96rem;
    height:2.90rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}
.fitnessnav{
    display: flex;
    justify-content: space-between;
    
}
.fitnessnav>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width:1.05rem;
    height:0.22rem;
    border:0.01rem solid #838181;
    border-radius: 0.11rem;
}
</style>